export function checkUserInfo(newUserData, cpfIndex, cnpjIndex) {
  let errorCount = 0
  console.log(newUserData.customer.firstname.length <3)
  if (newUserData.customer.firstname === "" || newUserData.customer.firstname.length <3) {
    console.log('ok')
    errorCount++
  }
  if (newUserData.customer.lastname === "" || newUserData.customer.lastname.length <2) {
    errorCount++
  }
  if (cpfIndex !== -1) {
    if (newUserData.customer.custom_attributes[cpfIndex].value === "" || newUserData.customer.custom_attributes[cpfIndex].value.length<14) {
      errorCount++
    }
  } else if (cnpjIndex !== -1) {
    if (newUserData.customer.custom_attributes[cnpjIndex].value === "") {
      errorCount++
    }
  }
  if (newUserData.customer.dob === "") {
    errorCount++
  }
  if (newUserData.customer.gender === 0) {
    errorCount++
  }
  if (newUserData.customer.addresses[0].telephone === "" || newUserData.customer.addresses[0].telephone.length<12) {
    errorCount++
  }
  if (errorCount === 0) {
    return false
  } else {
    return true
  }
}
export function checkUserckAddressInfo(
  newUserData,
  billingAndShippingAreTheSame
) {
  let errorCount = 0
  if (newUserData.customer.addresses[0].postcode === "" || newUserData.customer.addresses[0].postcode.length < 10) {
    errorCount++
  }
  if (newUserData.customer.addresses[0].street[0] === "") {
    errorCount++
  }
  if (newUserData.customer.addresses[0].street[2] === "") {
    errorCount++
  }
  if (newUserData.customer.addresses[0].street[1] === "") {
    errorCount++
  }
  if (newUserData.customer.addresses[0].city === "") {
    errorCount++
  }
  //address 2
  if (!billingAndShippingAreTheSame) {
    if (newUserData.customer.addresses[1].firstname) {
      errorCount++
    }
    if (newUserData.customer.addresses[1].lastname) {
      errorCount++
    }
    if (newUserData.customer.addresses[1].postcode === "") {
      errorCount++
    }
    if (newUserData.customer.addresses[1].street[0] === "") {
      errorCount++
    }
    if (newUserData.customer.addresses[1].street[2] === "") {
      errorCount++
    }
    if (newUserData.customer.addresses[1].street[1] === "") {
      errorCount++
    }
    if (newUserData.customer.addresses[1].city === "") {
      errorCount++
    }
  }

  if (errorCount === 0) {
    return false
  } else {
    return true
  }
}
export function checkUserPasswordInfo(newUserData, confirmPassword) {
  let errorCount = 0
  if (newUserData.customer.email === "") {
    errorCount++
  }
  if (newUserData.password === "") {
    errorCount++
  }
  if (confirmPassword === "") {
    errorCount++
  }

  if (errorCount === 0) {
    return false
  } else {
    return true
  }
}
