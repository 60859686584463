import React from 'react'
import { PageProps } from "gatsby"
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'

import SignUp from '../components/SignUp/SignUp'

const cadastro: React.FC<PageProps> = ({ location }) => {
   return (
      <Layout location={location}>
         <SEO title='Cadastro' />
         <SignUp />
      </Layout>
   )
}

export default cadastro
