import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputMask from 'react-input-mask';
import styles from '../SignUp.module.scss';
import Button from '@material-ui/core/Button';
import RequiredField from './requiredField';
import { checkUserInfo } from '../errorHandling';

type UserInformationType = {
   newUserData: any
   cpfIndex: number
   cnpjIndex: number
   handleUserInformationChange: (label: string, value: string | number) => void
   cx: any
   activeStep: number
   setActiveStep: (step: number) => void
}

const userInformation: React.FC<UserInformationType> = ({ newUserData, cpfIndex, cnpjIndex, handleUserInformationChange, cx, activeStep, setActiveStep }) => {
   const [errorHandler, setErrorHandler] = useState<boolean>(false);
   const className = cx({
      card: true,
      displayNone: activeStep !== 0
   });

   return (
      <div className={className}>
         {errorHandler && (newUserData.customer.firstname === "" || newUserData.customer.firstname.length < 3) && <RequiredField label="Mínimo de 3 letras" />}
         <TextField
            variant="outlined"
            error={errorHandler && (newUserData.customer.firstname === "" || newUserData.customer.firstname.length < 3)}
            value={newUserData.customer.firstname}
            onChange={(e) => { handleUserInformationChange('firstname', e.target.value.trimLeft()) }}
            fullWidth
            size="small"
            autoComplete="off"
            disabled={activeStep === 0 ? false : true}
            classes={{ root: styles.inputStyles }}
            placeholder="Digite o seu nome" />

         {errorHandler && (newUserData.customer.lastname === "" || newUserData.customer.lastname.length < 2) && <RequiredField label="Mínimo de 2 letras" />}
         <TextField
            variant="outlined"
            error={errorHandler && (newUserData.customer.lastname === "" || newUserData.customer.lastname.length < 2)}
            value={newUserData.customer.lastname}
            onChange={(e) => { handleUserInformationChange('lastname', e.target.value) }}
            fullWidth
            size="small"
            autoComplete="off"
            disabled={activeStep === 0 ? false : true}
            placeholder="Digite o seu sobrenome" />

         <div style={{ width: '100%' }}>
            {errorHandler &&
               (newUserData.customer?.custom_attributes[cpfIndex]?.value === "" || newUserData.customer.custom_attributes[cpfIndex].value.length < 14) &&
               <RequiredField label="CPF inválido" />}
         </div>
         

         {(cpfIndex !== -1) &&
            <InputMask
               mask="999.999.999-99"
               disabled={activeStep === 0 ? false : true}
               maskChar=""
               value={newUserData.customer.custom_attributes[cpfIndex].value}
               onChange={(e) => handleUserInformationChange('cpf', e.target.value)}>
               {
                  () =>
                     <TextField
                        variant="outlined"
                        error={errorHandler && (newUserData.customer.custom_attributes[cpfIndex].value === "" || newUserData.customer.custom_attributes[cpfIndex].value.length < 14)}
                        disabled={activeStep === 0 ? false : true}
                        type="text"
                        size="small"
                        autoComplete="off"
                        style={{ width: '100%' }}
                        placeholder="CPF"
                     />
               }
            </InputMask>
         }
         <br />
         <TextField
            variant="outlined"
            label="Data de nascimento"
            InputLabelProps={{ shrink: true }}
            error={errorHandler && newUserData.customer.dob === ""}
            value={newUserData.customer.dob}
            onChange={(e) => {
               if (e.target.value.length < 11) {
                  handleUserInformationChange('dob', e.target.value)
               }
            }}
            disabled={activeStep === 0 ? false : true}
            className={newUserData.customer.dob === "" ? "disabledCSS" : ""}
            placeholder="Data de nascimento"
            autoComplete="off"
            size="small"
            fullWidth
            type="date" />
         {errorHandler && newUserData.customer.gender === 0 && <RequiredField label="É necessário selecionar um gênero" />}
         <TextField
            variant="outlined"
            error={errorHandler && newUserData.customer.gender === 0}
            value={newUserData.customer.gender}
            disabled={activeStep === 0 ? false : true}
            onChange={(e) => handleUserInformationChange('gender', e.target.value)}
            autoComplete="off"
            placeholder="Gênero"
            className={newUserData.customer.gender === 0 ? "disabledCSS" : ""}
            size="small"
            fullWidth
            select>
            <MenuItem disabled value={0} style={{ fontSize: '14px', minHeight: '36px' }}>Gênero</MenuItem>
            <MenuItem value={1} classes={{ selected: styles.selectedItem }} className={styles.menuItem}>Masculino</MenuItem>
            <MenuItem value={2} classes={{ selected: styles.selectedItem }} className={styles.menuItem}>Feminino</MenuItem>
         </TextField>
         {errorHandler && (newUserData.customer.addresses[0].telephone === "" || newUserData.customer.addresses[0].telephone.length < 12) && <RequiredField label='Telefone inválido' />}
         <InputMask
            mask="(99)999999999"
            disabled={activeStep === 0 ? false : true}
            maskChar=""
            value={newUserData.customer.addresses[0].telephone}
            onChange={(e) => handleUserInformationChange('telephone', e.target.value)}>
            {
               () =>
                  <TextField
                     variant="outlined"
                     error={errorHandler && (newUserData.customer.addresses[0].telephone === "" || newUserData.customer.addresses[0].telephone.length < 12)}
                     size="small"
                     disabled={activeStep === 0 ? false : true}
                     autoComplete="off"
                     fullWidth
                     placeholder="Telefone" />
            }
         </InputMask>
         <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
               disabled
               variant="outlined"
               style={{ fontWeight: 'bold', textTransform: 'none', margin: '5px 15px', width: '35%' }}
            >
               Voltar
            </Button>
            <Button
               onClick={() => {
                  const error = checkUserInfo(newUserData, cpfIndex, cnpjIndex);
                  if (error) {
                     setErrorHandler(true);

                  } else {
                     setActiveStep(1)
                  }
               }}
               variant="outlined"
               style={{ backgroundColor: '#24cb99', fontWeight: 'bold', textTransform: 'none', color: 'white', border: 'none', margin: '5px 15px', width: '35%' }} >
               Próximo
            </Button>
         </div>
      </div>
   )
}

export default userInformation
