import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { createCustomer } from '../../../store/actions'
import RequiredField from './requiredField'
import { checkUserPasswordInfo } from '../errorHandling'

type PasswordInformationType = {
   newUserData: any
   handlePasswordInformationChange: any
   confirmPassword: any
   cx: any
   activeStep: number
   setActiveStep: any

}

const passwordInformation: React.FC<PasswordInformationType> = ({ newUserData, handlePasswordInformationChange, confirmPassword, cx, setActiveStep, activeStep }) => {
   const [errorHandler, setErrorHandler] = useState(false);
   const className = cx({
      card: true,
      displayNone: activeStep !== 2
   });

   return (
      <div className={className}>
         {errorHandler && newUserData.customer.email === "" && <RequiredField label='E-mail inválido' />}
         <TextField
            variant="outlined"
            error={errorHandler && newUserData.customer.email === ""}
            value={newUserData.customer.email}
            onChange={(e) => { handlePasswordInformationChange('email', e.target.value.trim()) }}
            size="small"
            disabled={activeStep === 2 ? false : true}
            fullWidth
            placeholder="Email" />
         {errorHandler && newUserData.password === "" && <RequiredField label='Senha inválida' />}
         <TextField
            variant="outlined"
            value={newUserData.password}
            error={errorHandler && newUserData.password === ""}
            onChange={(e) => { handlePasswordInformationChange('password', e.target.value.trim()) }}
            size="small"
            disabled={activeStep === 2 ? false : true}
            fullWidth
            type="password"
            placeholder="Senha" />
         {errorHandler && confirmPassword === "" && <RequiredField label='Senha inválida' />}
         <TextField
            variant="outlined"
            value={confirmPassword}
            error={errorHandler && confirmPassword === ""}
            onChange={(e) => { handlePasswordInformationChange('confirmPassword', e.target.value.trim()) }}
            size="small"
            disabled={activeStep === 2 ? false : true}
            fullWidth
            type="password"
            placeholder="Confirmar Senha" />

         <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
               variant="outlined"
               onClick={() => setActiveStep(1)}
               color="primary"
               style={{ fontWeight: 'bold', textTransform: 'none', margin: '5px 15px', width: '35%' }}
               >
               Voltar
            </Button>
            <Button
               onClick={() => {
                  const error = checkUserPasswordInfo(newUserData, confirmPassword)
                  if (error) {
                     setErrorHandler(true);
                  } else {
                     createCustomer(newUserData.customer, newUserData.password, confirmPassword)
                  }
               }}
               variant="outlined"
               style={{ backgroundColor: '#24cb99', fontWeight: 'bold', textTransform: 'none', color: 'white', border: 'none', margin: '5px 15px', width: '35%' }} >
               Finalizar
            </Button>
         </div>
      </div>
   )
}

export default passwordInformation
