import React, { useState } from 'react'
import styles from '../SignUp.module.scss'
import TextField from '@material-ui/core/TextField'
import Regions from '../../../data/regions.json'
import MenuItem from '@material-ui/core/MenuItem'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputMask from 'react-input-mask';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl'
import RequiredField from './requiredField';
import { checkUserckAddressInfo } from '../errorHandling'

type AddressInformationType = {
   newUserData: any
   billingAndShippingAreTheSame: boolean
   handleBillingAndShippingAreTheSame: (value: boolean) => void
   handleAddressInformationChange: (label: string, value: string | number, idx: number) => void
   cx: any
   activeStep: number
   setActiveStep: any
   handleCepConsult: (cep: any, index: string) => Promise<void>
}

type NewAddressType = {
   newUserData: any
   handleAddressInformationChange: (label: string, value: string | number, idx: number) => void
   activeStep: any
   errorHandler: any
   handleCepConsult: (cep: any, index: string) => Promise<void>
}

const NewAddress: React.FC<NewAddressType> = ({ newUserData, handleAddressInformationChange, activeStep, errorHandler, handleCepConsult }) => {
   return (
      <>
         {errorHandler && (newUserData.customer.addresses[1].firstname === "" || newUserData.customer.addresses[1].firstname.length < 3) && <RequiredField label='Mínimo de 3 letras' />}
         <TextField
            variant="outlined"
            value={newUserData.customer.addresses[1].firstname}
            error={errorHandler && (newUserData.customer.addresses[1].firstname === "" || newUserData.customer.addresses[1].firstname.length < 3)}
            onChange={(e) => handleAddressInformationChange('firstname', e.target.value.trimLeft(), 1)}
            size="small"
            disabled={activeStep === 1 ? false : true}
            fullWidth
            autoComplete="off"
            placeholder="Nome" />
         {errorHandler && (newUserData.customer.addresses[1].lastname === "" || newUserData.customer.addresses[1].lastname.length < 2) && <RequiredField label='Mínimo de 2 letras' />}
         <TextField
            variant="outlined"
            value={newUserData.customer.addresses[1].lastname}
            error={errorHandler && (newUserData.customer.addresses[1].lastname === "" || newUserData.customer.addresses[1].lastname.length < 2)}
            onChange={(e) => handleAddressInformationChange('lastname', e.target.value.trimLeft(), 1)}
            size="small"
            disabled={activeStep === 1 ? false : true}
            fullWidth
            autoComplete="off"
            placeholder="Sobrenome" />
         {errorHandler && (newUserData.customer.addresses[1].postcode === "" || newUserData.customer.addresses[1].postcode.length < 10) && <RequiredField label='CEP inválido' />}
         <InputMask
            mask="99.999-999"
            disabled={activeStep === 1 ? false : true}
            maskChar=""
            value={newUserData.customer.addresses[1].postcode}
            onChange={(e) => handleAddressInformationChange('postcode', e.target.value, 1)}>
            {
               () =>
                  <TextField
                     variant="outlined"
                     size="small"
                     error={errorHandler && (newUserData.customer.addresses[1].postcode === "" || newUserData.customer.addresses[1].postcode.length < 10)}
                     disabled={activeStep === 1 ? false : true}
                     autoComplete="off"
                     onChange={(e) => {
                        if (e.target.value.length === 10) {
                           handleCepConsult(e.target.value, 0);
                        }
                        handleAddressInformationChange('postcode', e.target.value, 0)
                     }}
                     fullWidth
                     placeholder="CEP" />
            }
         </InputMask>
         {errorHandler && newUserData.customer.addresses[1].street[0] === "" && <RequiredField label='Preencha a rua' />}
         <TextField
            variant="outlined"
            value={newUserData.customer.addresses[1].street[0]}
            error={errorHandler && newUserData.customer.addresses[1].street[0] === ""}
            onChange={(e) => handleAddressInformationChange('rua', e.target.value.trimLeft(), 1)}
            size="small"
            autoComplete="off"
            disabled={activeStep === 1 ? false : true}
            fullWidth
            placeholder="Rua" />
         <div style={{ display: 'flex' }}>
            {errorHandler && newUserData.customer.addresses[1]?.street[1] === "" && <div style={{ paddingLeft: '75%' }}><RequiredField label='Preencha o N°' /></div>}
         </div>
         <TextField
            variant="outlined"
            value={newUserData.customer.addresses[1]?.street[3]}
            onChange={(e) => handleAddressInformationChange('complemento', e.target.value.trimLeft(), 1)}
            size="small"
            disabled={activeStep === 1 ? false : true}
            fullWidth
            autoComplete="off"
            style={{ width: '72%', marginRight: '3%' }}
            placeholder="Complemento" />
         <TextField
            variant="outlined"
            value={newUserData.customer.addresses[1]?.street[1]}
            error={errorHandler && newUserData.customer.addresses[1]?.street[1] === ""}
            onChange={(e) => handleAddressInformationChange('numero', e.target.value, 1)}
            size="small"
            disabled={activeStep === 1 ? false : true}
            fullWidth
            autoComplete="off"
            style={{ width: '25%' }}
            type="number"
            placeholder="N°" />
         {errorHandler && newUserData.customer.addresses[1]?.street[2] === "" && <RequiredField label='Preencha o bairro' />}
         <TextField
            variant="outlined"
            value={newUserData.customer.addresses[1]?.street[2]}
            error={errorHandler && newUserData.customer.addresses[1]?.street[2] === ""}
            onChange={(e) => handleAddressInformationChange('bairro', e.target.value.trimLeft(), 1)}
            size="small"
            disabled={activeStep === 1 ? false : true}
            fullWidth
            autoComplete="off"
            placeholder="Bairro" />
         {errorHandler && newUserData.customer.addresses[1]?.city === "" && <RequiredField label='Preencha a cidade' />}
         <TextField
            variant="outlined"
            value={newUserData.customer.addresses[1]?.city}
            error={errorHandler && newUserData.customer.addresses[1]?.city === ""}
            onChange={(e) => handleAddressInformationChange('city', e.target.value.trimLeft(), 1)}
            size="small"
            disabled={activeStep === 1 ? false : true}
            fullWidth
            autoComplete="off"
            placeholder="Cidade" />
         {errorHandler && newUserData.customer.addresses[1]?.region.region_id === 0 && <RequiredField label='Selecione o estado' />}
         <TextField
            variant="outlined"
            value={newUserData.customer.addresses[1]?.region.region_id}
            error={errorHandler && newUserData.customer.addresses[1]?.region.region_id === 0}
            onChange={(e: any, child: any) => handleAddressInformationChange('region', child.props, 1)}
            size="small"
            autoComplete="off"
            className={newUserData.customer.addresses[1]?.region.region_id === 0 ? "disabledCSS" : ""}
            disabled={activeStep === 1 ? false : true}
            fullWidth
            select>
            <MenuItem disabled value={0} style={{ fontSize: '14px', minHeight: '36px' }}>Estado</MenuItem>
            {Regions.map(region => {
               return (
                  <MenuItem
                     id={region.code}
                     classes={{ selected: styles.selectedItem }}
                     className={styles.menuItem}
                     key={region.id}
                     value={region.id}>
                     {region.name}
                  </MenuItem>
               )
            })}
         </TextField>
         {errorHandler && newUserData.customer.addresses[1].telephone === "" && <RequiredField label='Telefone inválido' />}
         <InputMask
            mask="(99)999999999"
            disabled={activeStep === 1 ? false : true}
            maskChar=""
            value={newUserData.customer.addresses[1].telephone}
            autoComplete="off"
            onChange={(e) => handleAddressInformationChange('telephone', e.target.value, 1)}>
            {
               () =>
                  <TextField
                     variant="outlined"
                     size="small"
                     error={errorHandler && newUserData.customer.addresses[1].telephone === ""}
                     disabled={activeStep === 1 ? false : true}
                     fullWidth
                     placeholder="Telefone" />
            }
         </InputMask>
      </>)
}

const addressInformation: React.FC<AddressInformationType> = ({ newUserData, billingAndShippingAreTheSame, handleBillingAndShippingAreTheSame, handleAddressInformationChange, cx, activeStep, setActiveStep, handleCepConsult }) => {

   const [errorHandler, setErrorHandler] = useState(false);

   const className = cx({
      card: true,
      displayNone: activeStep !== 1
   });


   return (
      <>
         <div className={className}>
            {errorHandler && (newUserData.customer.addresses[0].postcode === "" || newUserData.customer.addresses[0].postcode.length < 10) && <RequiredField label="CEP inválido" />}
            <InputMask
               mask="99.999-999"
               maskChar=""
               value={newUserData.customer.addresses[0].postcode}
               disabled={activeStep === 1 ? false : true}
               autoComplete="off"
               onChange={(e) => {
                  if (e.target.value.length === 10) {
                     handleCepConsult(e.target.value, 0);
                  }
                  handleAddressInformationChange('postcode', e.target.value, 0)
               }}>
               {
                  () =>
                     <TextField
                        variant="outlined"
                        error={errorHandler && (newUserData.customer.addresses[0].postcode === "" || newUserData.customer.addresses[0].postcode.length < 10)}
                        size="small"
                        disabled={activeStep === 1 ? false : true}
                        autoComplete="off"
                        fullWidth
                        placeholder="CEP" />
               }
            </InputMask>

            {errorHandler && newUserData.customer.addresses[0].street[0] === "" && <RequiredField label="Preencha a rua" />}
            <TextField
               variant="outlined"
               value={newUserData.customer.addresses[0].street[0]}
               error={errorHandler && newUserData.customer.addresses[0].street[0] === ""}
               onChange={(e) => handleAddressInformationChange('rua', e.target.value.trimLeft(), 0)}
               size="small"
               disabled={activeStep === 1 ? false : true}
               autoComplete="off"
               fullWidth
               placeholder="Rua" />
            <div style={{ display: 'flex' }}>
            {errorHandler && newUserData.customer.addresses[0]?.street[1] === "" && <div style={{ paddingLeft: '75%' }}><RequiredField label='Preencha o N°' /></div>}
            </div>
            <TextField
               variant="outlined"
               value={newUserData.customer.addresses[0]?.street[3]}
               onChange={(e) => handleAddressInformationChange('complemento', e.target.value.trimLeft(), 0)}
               size="small"
               autoComplete="off"
               disabled={activeStep === 1 ? false : true}
               fullWidth
               style={{ width: '72%', marginRight: '3%' }}
               placeholder="Complemento" />

            <TextField
               variant="outlined"
               value={newUserData.customer.addresses[0]?.street[1]}
               error={errorHandler && newUserData.customer.addresses[0]?.street[1] === ""}
               type="number"
               onChange={(e) => handleAddressInformationChange('numero', e.target.value, 0)}
               size="small"
               autoComplete="off"
               disabled={activeStep === 1 ? false : true}
               fullWidth
               style={{ width: '25%' }}
               placeholder="N°" />
            {errorHandler && newUserData.customer.addresses[0]?.street[2] === "" && <RequiredField label='Preencha o bairro' />}
            <TextField
               variant="outlined"
               value={newUserData.customer.addresses[0]?.street[2]}
               error={errorHandler && newUserData.customer.addresses[0]?.street[2] === ""}
               onChange={(e) => handleAddressInformationChange('bairro', e.target.value.trimLeft(), 0)}
               size="small"
               autoComplete="off"
               disabled={activeStep === 1 ? false : true}
               fullWidth
               placeholder="Bairro" />
            {errorHandler && newUserData.customer.addresses[0]?.city === "" && <RequiredField label='Preencha a cidade' />}
            <TextField
               variant="outlined"
               value={newUserData.customer.addresses[0]?.city}
               error={errorHandler && newUserData.customer.addresses[0]?.city === ""}
               onChange={(e: any) => handleAddressInformationChange('city', e.target.value.trimLeft(), 0)}
               size="small"
               autoComplete="off"
               disabled={activeStep === 1 ? false : true}
               fullWidth
               placeholder="Cidade" />

            {errorHandler && newUserData.customer.addresses[0]?.region.region_id === 0 && <RequiredField label='Selecione o estado' />}
            <TextField
               variant="outlined"
               value={newUserData.customer.addresses[0]?.region.region_id}
               error={errorHandler && newUserData.customer.addresses[0]?.region.region_id === 0}
               onChange={(e: any, child: any) => handleAddressInformationChange('region', child.props, 0)}
               size="small"
               className={newUserData.customer.addresses[0]?.region.region_id === 0 ? "disabledCSS" : ""}
               disabled={activeStep === 1 ? false : true}
               fullWidth
               select>
               <MenuItem disabled value={0} style={{ fontSize: '14px', minHeight: '36px' }}>Estado</MenuItem>
               {Regions.map(region => {
                  return (
                     <MenuItem
                        id={region.code}
                        classes={{ selected: styles.selectedItem }}
                        className={styles.menuItem}
                        key={region.id}
                        value={region.id}>
                        {region.name}
                     </MenuItem>
                  )
               })}
            </TextField>
            <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0' }}>
               <RadioGroup value={(billingAndShippingAreTheSame) ? 1 : 2}>
                  <FormControl component="fieldset">
                     <FormControlLabel
                        disabled={activeStep === 1 ? false : true}
                        value={1}
                        onClick={() => { (activeStep === 1) && handleBillingAndShippingAreTheSame(true) || setErrorHandler(false) }}
                        control={<Radio size='small' color="primary" classes={{ root: styles.radio }} />}
                        label="Enviar para esse endereço"
                        classes={{ label: styles.label }}
                     />
                     <FormControlLabel
                        disabled={activeStep === 1 ? false : true}
                        value={2}
                        onClick={() => { (activeStep === 1) && handleBillingAndShippingAreTheSame(false) || setErrorHandler(false) }}
                        control={<Radio size='small' color="primary" classes={{ root: styles.radio }} />}
                        label="Enviar para endereço diferente"
                        classes={{ label: styles.label }}
                     />
                  </FormControl>
               </RadioGroup>
            </div>


            {!billingAndShippingAreTheSame &&
               <NewAddress newUserData={newUserData} handleAddressInformationChange={handleAddressInformationChange} activeStep={activeStep} errorHandler={errorHandler} handleCepConsult={handleCepConsult} />}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
               <Button
                  onClick={() => setActiveStep(0)}
                  color='primary'
                  variant="outlined"
                  style={{ fontWeight: 'bold', textTransform: 'none', margin: '5px 15px', width: '35%' }}
               >
                  Voltar
            </Button>
               <Button
                  onClick={() => {
                     const error = checkUserckAddressInfo(newUserData, billingAndShippingAreTheSame);
                     if (error) {
                        setErrorHandler(true)
                     } else {
                        setActiveStep(2)
                     }
                     console.log(error)
                     //setActiveStep(2)
                  }
                  }
                  variant="outlined"
                  style={{ backgroundColor: '#24cb99', fontWeight: 'bold', textTransform: 'none', color: 'white', border: 'none', margin: '5px 15px', width: '35%' }} >
                  Próximo
               </Button>
            </div>
         </div>


      </>

   )
}

export default addressInformation
