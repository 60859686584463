import React from 'react'

type requiredFieldType = {
   label: string
}

const requiredField: React.FC<requiredFieldType> = ({ label }) => {
   return (
      <div style={{ color: 'red', marginLeft: '4px', fontSize: '11px' }}>
         {label || 'Campo necessário'}
      </div>
   )
}

export default requiredField
