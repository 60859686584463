import React, { useState } from 'react'
import UserInformation from './components/UserInformation'
import AddressInformation from './components/addressInformation'
import PasswordInformation from './components/PasswordInformation'
import "./SignUp.scss"
import styles from './SignUp.module.scss'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import ClassNames from 'classnames/bind'
import { consultValidCep } from '../../store/actions'
import Regions from '../../data/regions.json'
import Button from '@material-ui/core/Button'
import { Link } from 'gatsby'

let cx = ClassNames.bind(styles)

type SignUpType = {

}

function getSteps() {
   return ['Nome', 'Endereço', 'Email'];
}

const SignUp: React.FC<SignUpType> = () => {
   const [newUserData, setNewUserData] = useState({
      "customer": {
         "email": "",
         "firstname": "",
         "lastname": "",
         "dob": "",
         "gender": 0,
         "custom_attributes": [
            {
               "attribute_code": "cpf",
               "value": ""
            }
         ],
         "addresses": [
            {
               "defaultShipping": false,
               "defaultBilling": true,
               "firstname": "",
               "lastname": "",
               "region": {
                  "region_code": "",
                  "region": "",
                  "region_id": 0
               },
               "postcode": "",
               "street": [
                  "",
                  "",
                  "",
                  ""
               ],
               "city": "",
               "telephone": "",
               "countryId": "BR"
            },
            {
               "defaultShipping": true,
               "defaultBilling": false,
               "firstname": "",
               "lastname": "",
               "region": {
                  "region_code": "",
                  "region": "",
                  "region_id": 0
               },
               "postcode": "",
               "street": [
                  "",
                  "",
                  "",
                  ""
               ],
               "city": "",
               "telephone": "",
               "countryId": "BR"
            }
         ]
      },
      "password": ""
   });
   const [billingAndShippingAreTheSame, setBillingAndShippingAreTheSame] = useState(true);
   const [confirmPassword, setConfirmPassword] = useState('');
   const [activeStep, setActiveStep] = useState(0);
   const cpfIndex = newUserData.customer.custom_attributes.findIndex(x => x.attribute_code === 'cpf');
   const cnpjIndex = newUserData.customer.custom_attributes.findIndex(x => x.attribute_code === 'cnpj');
   const steps = getSteps();

   const handleBillingAndShippingAreTheSame = (value: boolean) => {
      if (value === true) {
         setBillingAndShippingAreTheSame(value)
         const copyNewUserData = { ...newUserData };
         const copyAddress = { ...newUserData.customer.addresses[0] };
         copyAddress.defaultBilling = false;
         copyAddress.defaultShipping = true;
         copyNewUserData.customer.addresses[1] = copyAddress;
         setNewUserData(copyNewUserData);

      } else {
         setBillingAndShippingAreTheSame(value);
         const copyNewUserData = { ...newUserData };
         const copyAddress = {
            "defaultShipping": true,
            "defaultBilling": false,
            "firstname": "",
            "lastname": "",
            "region": {
               "region_code": "",
               "region": "",
               "region_id": 0
            },
            "postcode": "",
            "street": [
               "",
               "",
               "",
               ""
            ],
            "city": "",
            "telephone": "",
            "countryId": "BR"
         }
         copyNewUserData.customer.addresses[1] = copyAddress;
         setNewUserData(copyNewUserData);
      }
   }
   const handleUserInformationChange = (label: string, value: string | number) => {
      const copyNewUserData: any = { ...newUserData };
      if (billingAndShippingAreTheSame) {
         switch (label) {
            case 'firstname':
               copyNewUserData.customer.addresses[0][label] = value;
               copyNewUserData.customer.addresses[1][label] = value;
               copyNewUserData.customer[label] = value;
               break;
            case 'lastname':
               copyNewUserData.customer.addresses[0][label] = value;
               copyNewUserData.customer.addresses[1][label] = value;
               copyNewUserData.customer[label] = value;
               break;
            case 'document':
               if (value === 2) {
                  copyNewUserData.customer.custom_attributes[cpfIndex].attribute_code = "cnpj";
                  copyNewUserData.customer.custom_attributes[cpfIndex].value = "";
               } else {
                  copyNewUserData.customer.custom_attributes[cnpjIndex].attribute_code = "cpf";
                  copyNewUserData.customer.custom_attributes[cnpjIndex].value = "";
               }
               break;
            case 'cpf':
               copyNewUserData.customer.custom_attributes[cpfIndex].value = value;
               break;
            case 'cnpj':
               copyNewUserData.customer.custom_attributes[cnpjIndex].value = value;
               break;
            case 'telephone':
               copyNewUserData.customer.addresses[0][label] = value;
               copyNewUserData.customer.addresses[1][label] = value;
               break;
            default:
               copyNewUserData.customer[label] = value;
         }
      } else {
         switch (label) {
            case 'firstname':
               copyNewUserData.customer.addresses[0][label] = value;
               copyNewUserData.customer[label] = value;
               break;
            case 'lastname':
               copyNewUserData.customer.addresses[0][label] = value;
               copyNewUserData.customer[label] = value;
               break;
            case 'document':
               if (value === 2) {
                  copyNewUserData.customer.custom_attributes[cpfIndex].attribute_code = "cnpj";
                  copyNewUserData.customer.custom_attributes[cpfIndex].value = "";
               } else {
                  copyNewUserData.customer.custom_attributes[cnpjIndex].attribute_code = "cpf";
                  copyNewUserData.customer.custom_attributes[cnpjIndex].value = "";
               }
               break;
            case 'cpf':
               copyNewUserData.customer.custom_attributes[cpfIndex].value = value;
               break;
            case 'cnpj':
               copyNewUserData.customer.custom_attributes[cnpjIndex].value = value;
               break;
            case 'telephone':
               copyNewUserData.customer.addresses[0][label] = value;
               break;
            default:
               copyNewUserData.customer[label] = value;
         }
      }
      setNewUserData(copyNewUserData);
   }
   const handleAddressInformationChange = (label: string, value: string | number | any, idx: number) => {
      const copyNewUserData: any = { ...newUserData };
      console.log(label)
      console.log(value)
      if (billingAndShippingAreTheSame === true) {
         switch (label) {
            case 'rua':
               copyNewUserData.customer.addresses[0].street[0] = value;
               copyNewUserData.customer.addresses[1].street[0] = value;
               break;
            case 'complemento':
               copyNewUserData.customer.addresses[0].street[3] = value;
               copyNewUserData.customer.addresses[1].street[3] = value;
               break;
            case 'numero':
               copyNewUserData.customer.addresses[0].street[1] = value;
               copyNewUserData.customer.addresses[1].street[1] = value;
               break;
            case 'bairro':
               copyNewUserData.customer.addresses[0].street[2] = value;
               copyNewUserData.customer.addresses[1].street[2] = value;
               break;
            case 'region':
               copyNewUserData.customer.addresses[0].region = {
                  region: value.children,
                  region_code: value.id,
                  region_id: parseInt(value.value)
               }
               copyNewUserData.customer.addresses[1].region = {
                  region: value.children,
                  region_code: value.id,
                  region_id: parseInt(value.value)
               }
               break;
            default:
               copyNewUserData.customer.addresses[0][label] = value;
               copyNewUserData.customer.addresses[1][label] = value;
         }
      } else {
         switch (label) {
            case 'rua':
               copyNewUserData.customer.addresses[idx].street[0] = value;
               break;
            case 'complemento':
               copyNewUserData.customer.addresses[idx].street[3] = value;
               break;
            case 'numero':
               copyNewUserData.customer.addresses[idx].street[1] = value;
               break;
            case 'bairro':
               copyNewUserData.customer.addresses[idx].street[2] = value;
               break;
            case 'region':
               copyNewUserData.customer.addresses[idx].region = {
                  region: value.children,
                  region_code: value.id,
                  region_id: parseInt(value.value)
               }
               break;
            default:
               copyNewUserData.customer.addresses[idx][label] = value;
         }
      }

      setNewUserData(copyNewUserData)
   }

   const handlePasswordInformationChange = (label: string, value: any) => {
      const copyNewUserData: any = { ...newUserData };
      switch (label) {
         case 'confirmPassword':
            setConfirmPassword(value);
            break;
         case 'email':
            copyNewUserData.customer.email = value
            setNewUserData(copyNewUserData);
            break;
         case 'password':
            copyNewUserData.password = value;
            setNewUserData(copyNewUserData);
            break;
      }
   }
   
   const handleCepConsult = async (cep: any, index: string) => {
      const cepRes = await consultValidCep(cep.replace(/[^\w\s]/gi, ''))
      if (cepRes !== null) {
         console.log(cepRes)
         const copyNewUserData: any = JSON.parse(JSON.stringify(newUserData));
         if (index === 0) {
            copyNewUserData.customer.addresses[0].street[0] = cepRes?.logradouro
            copyNewUserData.customer.addresses[0].street[3] = cepRes?.complemento
            copyNewUserData.customer.addresses[0].street[2] = cepRes?.bairro
            copyNewUserData.customer.addresses[0].city = cepRes?.localidade
            const region = Regions.find(x => x.code == cepRes?.uf)
            copyNewUserData.customer.addresses[0].region = {
               region_code: region?.code, region: region?.name, region_id: parseInt(region.id)
            }
            copyNewUserData.customer.addresses[1].street[0] = cepRes?.logradouro
            copyNewUserData.customer.addresses[1].street[3] = cepRes?.complemento
            copyNewUserData.customer.addresses[1].street[2] = cepRes?.bairro
            copyNewUserData.customer.addresses[1].city = cepRes?.localidade
            copyNewUserData.customer.addresses[1].region = {
               region_code: region?.code, region: region?.name, region_id: parseInt(region.id)
            }
         } else {
            copyNewUserData.customer.addresses[index].street[0] = cepRes?.logradouro
            copyNewUserData.customer.addresses[index].street[3] = cepRes?.complemento
            copyNewUserData.customer.addresses[index].street[2] = cepRes?.bairro
            copyNewUserData.customer.addresses[index].city = cepRes?.localidade
            const region = Regions.find(x => x.code == cepRes?.uf)
            console.log(copyNewUserData);
            copyNewUserData.customer.addresses[index].region = {
               region_code: region?.code, region: region?.name, region_id: parseInt(region.id)
            }
         }

         setNewUserData(copyNewUserData)
      }
   }


   return (
      <div className="cardsDiv" style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'center' }}>
         <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px', fontWeight: 'bold' }}>
            Criar conta
      </div>
         <div style={{ width: '100%' }}>
            <Stepper classes={{ root: styles.stepper }} activeStep={activeStep} alternativeLabel>
               {steps.map((label) => (
                  <Step key={label}>
                     <StepLabel
                        StepIconProps={{ classes: { completed: styles.completedIcon } }}
                        classes={{ alternativeLabel: styles.rootLabel, active: styles.active, completed: styles.completed, label: styles.label, iconContainer: styles.iconContainer }}>
                        {label}
                     </StepLabel>
                  </Step>
               ))}
            </Stepper>
         </div>
         <div style={{ display: 'flex', flexWrap: 'wrap', width: '90%', justifyContent: 'space-between' }}>
            <UserInformation
               newUserData={newUserData}
               cpfIndex={cpfIndex}
               cnpjIndex={cnpjIndex}
               handleUserInformationChange={handleUserInformationChange}
               cx={cx}
               activeStep={activeStep}
               setActiveStep={setActiveStep}
            />
            <AddressInformation
               newUserData={newUserData}
               billingAndShippingAreTheSame={billingAndShippingAreTheSame}
               handleBillingAndShippingAreTheSame={handleBillingAndShippingAreTheSame}
               handleAddressInformationChange={handleAddressInformationChange}
               handleCepConsult={handleCepConsult}
               cx={cx}
               activeStep={activeStep}
               setActiveStep={setActiveStep}
            />
            <PasswordInformation
               newUserData={newUserData}
               handlePasswordInformationChange={handlePasswordInformationChange}
               confirmPassword={confirmPassword}
               cx={cx}
               activeStep={activeStep}
               setActiveStep={setActiveStep}
            />
         </div>
      </div>
   )
}

export default SignUp
